import { SET_UNREAD_NOTIFICATION } from "../actions/type";

const initialState = {
  unread_notifications: 0
}

export const setUnreadNotification = payload => ({
  type: SET_UNREAD_NOTIFICATION,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATION:
      return {
        ...state,
        unread_notifications: action.payload,
      };
    default:
      return state;
  }
}