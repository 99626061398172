import React, { lazy } from "react"

const ZitronDashboard = lazy(() => import("./ZitronDashboard"))
const Main = lazy(() => import("./Dashboard/Main"))
const UserAccess = lazy(() => import("./SuperAdmin/UserAccess"))
const ModulesManagement = lazy(() => import("./SuperAdmin/Modules"))
const OrganisationsMngmt = lazy(() => import("./SystemSetup/Organisations"))
const Employee = lazy(() => import("./SystemSetup/Employee"))
const Customer = lazy(() => import("./SystemSetup/Customer"))
const Supplier = lazy(() => import("./SystemSetup/Supplier"))
const BankGroup = lazy(() => import("./SystemSetup/Bank"))
const Settings = lazy(() => import("./SystemSetup/Settings"))
const ControlSetup = lazy(() => import("./SystemSetup/ControlSetup"));
const IntegrationSetup = lazy(() => import("./SystemSetup/IntegrationSetup"));
const SalesTransactions = lazy(() => import("./Sales/Transactions"))
const SalesMaintenance = lazy(() => import("./Sales/Maintenance"))
const SalesInquiry = lazy(() => import("./Sales/Inquiry"));
const SalesReports = lazy(() => import("./Sales/Reports"));
const CommissionMaintenance = lazy(() => import("./Commission/Maintenance"))
const CommissionReport = lazy(() => import("./Commission/Reports"))
const PurchaseTransactions = lazy(() => import("./Purchase/Transactions"))
const InventoryMaintenance = lazy(() => import("./Inventory/Maintenance"))
const InventoryTransactions = lazy(() => import("./Inventory/Transactions"))
const InventoryInquiry = lazy(() => import("./Inventory/Inquiry"))
const InventoryReport = lazy(() => import("./Inventory/Report"))
const InventoryProcessing = lazy(() => import("./Inventory/Processing"))
const DeliveryMaintenance = lazy(() => import("./Delivery/Maintenance"))
const DeliveryInquiry = lazy(() => import("./Delivery/Inquiry"))
const DeliveryTransactions = lazy(() => import("./Delivery/Transactions"))
const DeliveryReport = lazy(() => import("./Delivery/Report"))
const GLMaintenance = lazy(() => import("./GeneralLedger/Maintenance"))
const GLTransactions = lazy(() => import("./GeneralLedger/Transactions"))
const GLProcessing = lazy(() => import("./GeneralLedger/Processing"))
const GLReport = lazy(() => import("./GeneralLedger/Report"))
const ReceivableMaintenance = lazy(() => import("./Receivable/Maintenance"))
const ReceivableTransactions = lazy(() => import("./Receivable/Transactions"))
const ReceivableProcessing = lazy(() => import("./Receivable/Processing"))
const ReceivableInquiry = lazy(() => import("./Receivable/Inquiry"))
const ReceivableReport = lazy(() => import("./Receivable/Report"))
const PayableInquiry = lazy(() => import("./Payable/Inquiry"))
const PayableTransactions = lazy(() => import("./Payable/Transactions"))
const PayableProcessing = lazy(() => import("./Payable/Processing"))
const PayableReport = lazy(() => import("./Payable/Report"))
const HRLiteTransactions = lazy(() => import("./HRLite/Transactions"))
const HRLiteMaintenance = lazy(() => import("./HRLite/Maintenance"))
const DealerSetting = lazy(() => import("./DealerPortal/Setting"))
const AuditLog = lazy(() => import("./SystemReport/AuditLog"))
const DealerPortalMaintenance = lazy(() => import("./DealerPortal/Maintenance"))
const DealerPortalControlSetup = lazy(() => import("./DealerPortal/ControlSetup"));
const DealerPortalInquiry = lazy(() => import("./DealerPortal/Inquiry"));
const DealerPortalTransaction = lazy(() => import("./DealerPortal/Transactions"));
const SamsungMaintenance = lazy(() => import("./Samsung/Maintenance"))
const ChangePassword = lazy(() => import("./ChangePassword"))
const Report = lazy(() => import("./SystemSetup/Report"))

export const routes = [
  {
    parent: "Dashboard",
    routes: [
      {
        path: "/main",
        exact: true,
        label: "Main",
        render: props => <Main {...props} />
      }
    ]
  },
  {
    parent: "System Setup",
    routes: [
      {
        path: "/system_setup/organisation",
        exact: true,
        label: "Organisation",
        render: props => <OrganisationsMngmt {...props} />
      },
      {
        path: "/system_setup/employee",
        exact: true,
        label: "Employee",
        render: props => <Employee {...props} />
      },
      {
        path: "/system_setup/customer",
        exact: true,
        label: "Customer",
        render: props => <Customer {...props} />
      },
      {
        path: "/system_setup/supplier",
        exact: true,
        label: "Supplier",
        render: props => <Supplier {...props} />
      },
      {
        path: "/system_setup/bank",
        exact: true,
        label: "Bank",
        render: props => <BankGroup {...props} />
      },
      {
        path: "/system_setup/settings",
        exact: true,
        label: "Settings",
        render: props => <Settings {...props} />
      },
      {
        path: "/system_setup/integration_setup",
        exact: true,
        label: "Integration Setup",
        render: props => <IntegrationSetup {...props} />
      },
      {
        path: "/system_setup/control_setup",
        exact: true,
        label: "Control Setup",
        render: props => <ControlSetup {...props} />
      },
      {
        path: "/system_setup/report",
        exact: true,
        label: "Report",
        render: props => <Report {...props} />
      },
    ]
  },
  {
    parent: "Sales",
    routes: [
      {
        path: "/sales/transactions",
        exact: true,
        label: "Transactions",
        render: props => <SalesTransactions {...props} />
      },
      {
        path: "/sales/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <SalesMaintenance {...props} />
      },
      {
        path: '/sales/inquiry',
        label: "Inquiry",
        render: (props) => <SalesInquiry {...props} />
      },
      {
        path: '/sales/reports',
        label: "Reports",
        render: (props) => <SalesReports {...props} />
      },
    ]
  },
  {
    parent: "Commission",
    routes: [
      {
        path: "/commission/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <CommissionMaintenance {...props} />
      },
      {
        path: "/commission/reports",
        exact: true,
        label: "Reports",
        render: props => <CommissionReport {...props} />
      },
    ]
  },
  {
    parent: "Purchase",
    routes: [
      {
        path: "/purchase/transactions",
        exact: true,
        label: "Transactions",
        render: props => <PurchaseTransactions {...props} />
      }
    ]
  },
  {
    parent: "Inventory",
    routes: [
      {
        path: "/inventory/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <InventoryMaintenance {...props} />
      },
      {
        path: "/inventory/transactions",
        exact: true,
        label: "Transactions",
        render: props => <InventoryTransactions {...props} />
      },
      {
        path: "/inventory/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <InventoryInquiry {...props} />
      },
      {
        path: "/inventory/report",
        exact: true,
        label: "Reports",
        render: props => <InventoryReport {...props} />
      },
      {
        path: "/inventory/processing",
        exact: true,
        label: "Processing",
        render: props => <InventoryProcessing {...props} />
      }
    ]
  },
  {
    parent: "Delivery",
    routes: [
      {
        path: "/delivery/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <DeliveryMaintenance {...props} />
      },
      {
        path: "/delivery/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <DeliveryInquiry {...props} />
      },
      {
        path: "/delivery/transactions",
        exact: true,
        label: "Transactions",
        render: props => <DeliveryTransactions {...props} />
      },
      {
        path: "/delivery/report",
        exact: true,
        label: "Reports",
        render: props => <DeliveryReport {...props} />
      }
    ]
  },
  {
    parent: "General Ledger",
    routes: [
      {
        path: "/generalLedger/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <GLMaintenance {...props} />
      },
      {
        path: "/generalLedger/transactions",
        exact: true,
        label: "Transactions",
        render: props => <GLTransactions {...props} />
      },
      {
        path: "/generalLedger/processing",
        exact: true,
        label: "Processing",
        render: props => <GLProcessing {...props} />
      },
      {
        path: "/generalLedger/report",
        exact: true,
        label: "Reports",
        render: props => <GLReport {...props} />
      }
    ]
  },
  {
    parent: "Account Receivable",
    routes: [
      {
        path: "/receivable/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <ReceivableMaintenance {...props} />
      },
      {
        path: "/receivable/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <ReceivableInquiry {...props} />
      },
      {
        path: "/receivable/transactions",
        exact: true,
        label: "Transactions",
        render: props => <ReceivableTransactions {...props} />
      },
      {
        path: "/receivable/processing",
        exact: true,
        label: "Processing",
        render: props => <ReceivableProcessing {...props} />
      },
      {
        path: "/receivable/report",
        exact: true,
        label: "Reports",
        render: props => <ReceivableReport {...props} />
      },
    ]
  },

  {
    parent: "Account Payable",
    routes: [
      {
        path: "/payable/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <PayableInquiry {...props} />
      },
      {
        path: "/payable/transactions",
        exact: true,
        label: "Transactions",
        render: props => <PayableTransactions {...props} />
      },
      {
        path: "/payable/processing",
        exact: true,
        label: "Processing",
        render: props => <PayableProcessing {...props} />
      },
      {
        path: "/payable/report",
        exact: true,
        label: "Reports",
        render: props => <PayableReport {...props} />
      }
    ]
  },
  {
    parent: "HR Lite",
    routes: [
      {
        label: "Maintenance",
        path: "/hr_lite/maintenance",
        exact: true,
        render: props => <HRLiteMaintenance {...props} />
      },
      {
        label: "Transactions",
        path: "/hr_lite/transactions",
        exact: true,
        render: props => <HRLiteTransactions {...props} />
      },
  ]
  },
  {
    parent: "Dealer Portal",
    routes: [
      {
        label: "Maintenance",
        path: "/dealer_portal/maintenance",
        exact: true,
        render: props => <DealerPortalMaintenance {...props} />
      },
      {
        path: "/dealer_portal/setting",
        exact: true,
        label: "Setting",
        render: props => <DealerSetting {...props} />
      },
      {
        label: "Control Setup",
        path: "/dealer_portal/control_setup",
        exact: true,
        render: props => <DealerPortalControlSetup {...props} />
      },
      {
        label: "Inquiry",
        path: "/dealer_portal/inquiry",
        exact: true,
        render: props => <DealerPortalInquiry {...props} />
      },
      {
        label: "Transactions",
        path: "/dealer_portal/transactions",
        exact: true,
        render: props => <DealerPortalTransaction {...props} />
      },
    ]
  },
  {
    parent: "Samsung",
    routes: [
      {
        label: "Maintenance",
        path: "/samsung/maintenance",
        exact: true,
        render: props => <SamsungMaintenance {...props} />
      }
    ]
  },
  {
    parent: "System Report",
    routes: [
      {
        label: "Audit Log",
        path: "/system_report/audit_log",
        exact: true,
        render: props => <AuditLog {...props} />
      }
    ]
  }
]

export const impersonateRoutes = [
  {
    parent: "Dashboard",
    routes: [
      {
        path: "admin-impersonate/dashboard/main",
        exact: true,
        label: "Main",
        render: props => <Main {...props} />
      }
    ]
  },
  {
    parent: "System Setup",
    routes: [
      {
        path: "admin-impersonate/dashboard/system_setup/organisation",
        exact: true,
        label: "Organisation",
        render: props => <OrganisationsMngmt {...props} />
      },
      {
        path: "admin-impersonate/system_setup/employee",
        exact: true,
        label: "Employee",
        render: props => <Employee {...props} />
      },
      {
        path: "admin-impersonate/system_setup/customer",
        exact: true,
        label: "Customer",
        render: props => <Customer {...props} />
      },
      {
        path: "admin-impersonate/system_setup/supplier",
        exact: true,
        label: "Supplier",
        render: props => <Supplier {...props} />
      },
      {
        path: "admin-impersonate/system_setup/bank",
        exact: true,
        label: "Bank",
        render: props => <BankGroup {...props} />
      },
      {
        path: "admin-impersonate/system_setup/settings",
        exact: true,
        label: "Settings",
        render: props => <Settings {...props} />
      },
      {
        path: "admin-impersonate/system_setup/control_setup",
        exact: true,
        label: "Control Setup",
        render: props => <ControlSetup {...props} />
      }
    ]
  },
  {
    parent: "Sales",
    routes: [
      {
        path: "admin-impersonate/sales/transactions",
        exact: true,
        label: "Transactions",
        render: props => <SalesTransactions {...props} />
      },
      {
        path: "admin-impersonate/sales/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <SalesMaintenance {...props} />
      },
      {
        path: 'admin-impersonate/sales/inquiry',
        label: "Inquiry",
        render: (props) => <SalesInquiry {...props} />
      },
      {
        path: 'admin-impersonate/sales/reports',
        label: "Reports",
        render: (props) => <SalesReports {...props} />
      },
    ]
  },
  {
    parent: "Commission",
    routes: [
      {
        path: "admin-impersonate/commission/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <CommissionMaintenance {...props} />
      },
      {
        path: "admin-impersonate/commission/reports",
        exact: true,
        label: "Reports",
        render: props => <CommissionReport {...props} />
      }
    ]
  },
  {
    parent: "Purchase",
    routes: [
      {
        path: "admin-impersonate/purchase/transactions",
        exact: true,
        label: "Transactions",
        render: props => <PurchaseTransactions {...props} />
      }
    ]
  },
  {
    parent: "Inventory",
    routes: [
      {
        path: "admin-impersonate/inventory/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <InventoryMaintenance {...props} />
      },
      {
        path: "admin-impersonate/inventory/transactions",
        exact: true,
        label: "Transactions",
        render: props => <InventoryTransactions {...props} />
      },
      {
        path: "admin-impersonate/inventory/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <InventoryInquiry {...props} />
      },
      {
        path: "admin-impersonate/inventory/report",
        exact: true,
        label: "Reports",
        render: props => <InventoryReport {...props} />
      }
    ]
  },
  {
    parent: "Delivery",
    routes: [
      {
        path: "admin-impersonate/delivery/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <DeliveryMaintenance {...props} />
      },
      {
        path: "admin-impersonate/delivery/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <DeliveryInquiry {...props} />
      },
      {
        path: "admin-impersonate/delivery/transactions",
        exact: true,
        label: "Transactions",
        render: props => <DeliveryTransactions {...props} />
      },
      {
        path: "admin-impersonate/delivery/report",
        exact: true,
        label: "Reports",
        render: props => <DeliveryReport {...props} />
      }
    ]
  },
  {
    parent: "General Ledger",
    routes: [
      {
        path: "admin-impersonate/generalLedger/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <GLMaintenance {...props} />
      },
      {
        path: "admin-impersonate/generalLedger/transactions",
        exact: true,
        label: "Transactions",
        render: props => <GLTransactions {...props} />
      },
      {
        path: "admin-impersonate/generalLedger/processing",
        exact: true,
        label: "Processing",
        render: props => <GLProcessing {...props} />
      },
      {
        path: "admin-impersonate/generalLedger/report",
        exact: true,
        label: "Reports",
        render: props => <GLReport {...props} />
      },
    ]
  },
  {
    parent: "Account Receivable",
    routes: [
      {
        path: "admin-impersonate/receivable/maintenance",
        exact: true,
        label: "Maintenance",
        render: props => <ReceivableMaintenance {...props} />
      },
      {
        path: "admin-impersonate/receivable/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <ReceivableInquiry {...props} />
      },
      {
        path: "admin-impersonate/receivable/transactions",
        exact: true,
        label: "Transactions",
        render: props => <ReceivableTransactions {...props} />
      },
      {
        path: "admin-impersonate/receivable/processing",
        exact: true,
        label: "Processing",
        render: props => <ReceivableProcessing {...props} />
      },
      {
        path: "admin-impersonate/receivable/report",
        exact: true,
        label: "Reports",
        render: props => <ReceivableReport {...props} />
      }
    ]
  },
  {
    parent: "Account Payable",
    routes: [
      {
        path: "admin-impersonate/payable/transactions",
        exact: true,
        label: "Transactions",
        render: props => <PayableTransactions {...props} />
      },
      {
        path: "admin-impersonate/payable/inquiry",
        exact: true,
        label: "Inquiry",
        render: props => <PayableInquiry {...props} />
      },
      {
        path: "admin-impersonate/payable/processing",
        exact: true,
        label: "Processing",
        render: props => <PayableProcessing {...props} />
      },
      {
        path: "admin-impersonate/payable/report",
        exact: true,
        label: "Reports",
        render: props => <PayableReport {...props} />
      },
    ]
  },
  {
    parent: "HR Lite",
    routes: [
      {
        label: "Maintenance",
        path: "admin-impersonate/hr_lite/maintenance",
        exact: true,
        render: props => <HRLiteMaintenance {...props} />
      },
      {
        label: "Transactions",
        path: "admin-impersonate/hr_lite/transactions",
        exact: true,
        render: props => <HRLiteTransactions {...props} />
      },
    ]
  },
  {
    parent: "Dealer Portal",
    routes: [
      {
        label: "Maintenance",
        path: "admin-impersonate/dealer_portal/maintenance",
        exact: true,
        render: props => <DealerPortalMaintenance {...props} />
      },
      {
        path: "admin-impersonate/dealer_portal/setting",
        exact: true,
        label: "Setting",
        render: props => <DealerSetting {...props} />
      },
      {
        label: "Control Setup",
        path: "admin-impersonate/dealer_portal/control_setup",
        exact: true,
        render: props => <DealerPortalControlSetup {...props} />
      },
      {
        label: "Inquiry",
        path: "admin-impersonate/dealer_portal/inquiry",
        exact: true,
        render: props => <DealerPortalInquiry {...props} />
      },
      {
        label: "Transactions",
        path: "admin-impersonate/dealer_portal/transactions",
        exact: true,
        render: props => <DealerPortalTransaction {...props} />
      },
    ]
  },
  {
    parent: "System Report",
    routes: [
      {
        label: "Audit Log",
        path: "admin-impersonate/system_report/audit_log",
        exact: true,
        render: props => <AuditLog {...props} />
      }
    ]
  }
]

export const superAdminRoutes = [
  {
    path: "/super_admin/user_access",
    exact: true,
    render: props => <UserAccess {...props} />
  },
  {
    path: "/super_admin/modules",
    exact: true,
    render: props => <ModulesManagement {...props} />
  },
  {
    path: "admin-impersonate/super_admin/user_access",
    exact: true,
    render: props => <UserAccess {...props} />
  },
  {
    path: "admin-impersonate/super_admin/modules",
    exact: true,
    render: props => <ModulesManagement {...props} />
  }
]

export const utilRoutes = [
  {
    path: "",
    exact: true,
    render: props => <ZitronDashboard {...props} />
  },
  {
    path: "/change_password",
    render: props => <ChangePassword {...props} />
  },
  {
    path: "/admin-impersonate",
    exact: true,
    render: props => <ZitronDashboard {...props} />
  },
  {
    path: "admin-impersonate/change_password",
    exact: true,
    render: props => <ChangePassword {...props} />
  }
]
